import React from "react";
import Logo from './Logo';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPhone} from '@fortawesome/free-solid-svg-icons';

const Header = () => {
    return (
        <header className="fixed left-1/2 transform -translate-x-1/2 z-50 w-full max-w-7xl">
            <div className="m-4 px-6 py-4 rounded-full bg-white bg-opacity-60 backdrop-blur-md shadow-lg">
                <div className="flex items-center justify-between">
                    <nav className="hidden md:flex items-center space-x-6 flex-1">
                        <button className="text-sm font-medium text-gray-600 hover:text-blue-600 transition-colors">
                            Porquê?
                        </button>
                        <button className="text-sm font-medium text-gray-600 hover:text-blue-600 transition-colors">
                            Soluções
                        </button>
                        <button className="text-sm font-medium text-gray-600 hover:text-blue-600 transition-colors">
                            Funcionalidades
                        </button>
                    </nav>
                    <div className="flex justify-center flex-1 -ml-1">
                        <Logo classNames="text-3xl" />
                    </div>
                    <div className="hidden md:flex items-center space-x-4 flex-1 justify-end">
                        <a href="/">
                            <button className="px-4 py-2 border border-blue-500 text-blue-500 rounded-full hover:bg-blue-500 hover:text-white transition-colors">
                                Login
                            </button>
                        </a>
                        <a href="/contact">
                            <button className="px-4 py-2 bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-full hover:from-blue-600 hover:to-purple-600 transition-colors">
                                <FontAwesomeIcon icon={faPhone} className="me-2" />
                                Fale connosco
                            </button>
                        </a>
                    </div>
                    <button className="md:hidden text-gray-600 hover:text-blue-600 transition-colors">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="lucide lucide-menu"
                        >
                            <line x1="4" y1="6" x2="20" y2="6"></line>
                            <line x1="4" y1="12" x2="20" y2="12"></line>
                            <line x1="4" y1="18" x2="20" y2="18"></line>
                        </svg>
                    </button>
                </div>
            </div>
        </header>
    );
};

export default Header;
