import React from 'react';

const Logo = ({ classNames = "text-4xl" }) => (
    <div className={classNames}>
        scalin
        <span className="bg-gradient-to-r from-blue-500 to-purple-500 text-transparent bg-clip-text">
            G.PT
        </span>
    </div>
);

export default Logo;