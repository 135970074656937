import React from 'react';
import Logo from './Logo';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faEnvelope,
    faFileContract,
    faUserShield,
} from '@fortawesome/free-solid-svg-icons';

const Footer = () => (
    <footer className="bg-gradient-to-r from-blue-900 to-purple-900 text-white py-10 z-10">
        <div
            className="container mx-auto  px-20 flex flex-col md:flex-row justify-between items-center">
            <div className="text-center md:text-left mb-6 md:mb-0">
                <h3 className="text-2xl font-bold mb-2">
                    <Logo />
                </h3>

                <p className="text-blue-200">
                    © Scaling.pt 2024. Todos os direitos reservados
                </p>
            </div>

            <nav className="flex flex-wrap justify-center md:justify-end gap-10">
                <a href="/privacy" className="hover:text-blue-300 transition-colors">
                    <FontAwesomeIcon icon={faFileContract} className="me-2"/>
                    Política de Privacidade
                </a>

                <a href="/terms" className="hover:text-blue-300 transition-colors">
                    <FontAwesomeIcon icon={faUserShield} className="me-2"/>
                    Termos & Condições
                </a>

                <a href="/" className="hover:text-blue-300 transition-colors">
                    <FontAwesomeIcon icon={faEnvelope} className="me-2"/>
                    Contacte-nos
                </a>
            </nav>
        </div>
    </footer>
);

export default Footer;