import React from 'react';

const BackgroundGrid = () => (
    <div className="absolute inset-0 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-50 via-purple-50 to-pink-50"></div>
        <svg className="absolute inset-0 w-full h-full" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <radialGradient id="fadeGradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" stopColor="white" stopOpacity="1"/>
                    <stop offset="80%" stopColor="white" stopOpacity="0.5"/>
                    <stop offset="100%" stopColor="white" stopOpacity="0"/>
                </radialGradient>
                <pattern id="smallGrid" width="20" height="20" patternUnits="userSpaceOnUse">
                    <path d="M 20 0 L 0 0 0 20" fill="none" stroke="rgba(0, 0, 0, 0.1)" strokeWidth="0.3"/>
                </pattern>
                <pattern id="grid" width="100" height="100" patternUnits="userSpaceOnUse">
                    <rect width="100" height="100" fill="url(#smallGrid)"/>
                    <path d="M 100 0 L 0 0 0 100" fill="none" stroke="rgba(0, 0, 0, 0.2)" strokeWidth="0.8"/>
                </pattern>
            </defs>
            <rect width="100%" height="100%" fill="url(#grid)"/>
            <rect width="100%" height="100%" fill="url(#fadeGradient)"/>
        </svg>
        <div className="absolute inset-0">
            <div
                className="absolute bg-white rounded-full"
                style={{
                    width: "1.99627px",
                    height: "3.06095px",
                    left: "81.1926%",
                    top: "86.3562%",
                    opacity: 0.849194,
                }}
            ></div>
            <div
                className="absolute bg-white rounded-full"
                style={{
                    width: "3.82751px",
                    height: "2.84084px",
                    left: "47.4161%",
                    top: "47.8375%",
                    opacity: 0.977627,
                }}
            ></div>
            <div
                className="absolute bg-white rounded-full"
                style={{
                    width: "3.27513px",
                    height: "2.13565px",
                    left: "37.925%",
                    top: "72.8376%",
                    opacity: 0.523839,
                }}
            ></div>
            {/* Add all other "absolute bg-white rounded-full" elements here */}
            <div
                className="absolute bg-white rounded-full"
                style={{
                    width: "4.73369px",
                    height: "2.89066px",
                    left: "48.8343%",
                    top: "50.2702%",
                    opacity: 0.848092,
                }}
            ></div>
        </div>
    </div>
);

export default BackgroundGrid;