import React from 'react';
import Header from '../components/Header';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faChevronRight, faPhone, faPlay} from '@fortawesome/free-solid-svg-icons';
import Footer from '../components/Footer';
import BackgroundGrid from '../components/BackgroundGrid';

const Home = () => {
    return (
        <div>
            <BackgroundGrid />

            <Header/>

            <section className="min-h-screen flex flex-col justify-between relative">
                <div className="flex-grow flex flex-col justify-center items-center mt-32 md:mt-38">
                    <div className="text-center z-10 mb-12">
                        <h1 className="font-bold mb-4 leading-tight">
                            <span className="text-black text-4xl md:text-5xl">Conheça a Eva, </span>
                            <br/>
                            <span className="bg-gradient-to-r from-blue-500 to-purple-500 text-transparent bg-clip-text text-2xl md:text-5xl">
              A Sua Melhor Colaboradora
            </span>
                        </h1>
                        <p className="text-xl md:text-2xl mb-8 text-gray-600">
                            Desde <b>Apoio ao Cliente</b> a <b>Vendas</b> e além, <br />a
                            <b><i> Scaling</i></b> trata do assunto
                        </p>
                    </div>
                </div>
            </section>

            <section id="features" className="py-20 bg-gradient-to-br from-blue-50 to-purple-50">
                <div className="container mx-auto px-4">
                    <h2 className="text-4xl font-bold mb-12 text-center bg-gradient-to-r from-purple-600 to-pink-500 text-transparent bg-clip-text">
                        Para a sua empresa
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
                        <div
                            className="bg-gradient-to-br from-pink-50 to-pink-25 rounded-lg shadow-lg p-6 transition-transform hover:scale-105">
                            <h3 className="text-xl font-semibold mb-2 text-gray-800">
                                Atendimento Telefónico Automatizado 24/7
                            </h3>
                            <p className="text-gray-600">
                                Garanta que os seus clientes conseguem sempre contactá-lo com atendimento contínuo, 24
                                horas por dia.
                            </p>
                        </div>
                        <div
                            className="bg-gradient-to-br from-pink-50 to-pink-25 rounded-lg shadow-lg p-6 transition-transform hover:scale-105">
                            <h3 className="text-xl font-semibold mb-2 text-gray-800">
                                Encaminhamento Inteligente de Chamadas
                            </h3>
                            <p className="text-gray-600">
                                Re-encaminhe as chamadas para departamentos ou colaboradores adequados, conforme as
                                necessidades do cliente.
                            </p>
                        </div>
                        <div
                            className="bg-gradient-to-br from-pink-50 to-pink-25 rounded-lg shadow-lg p-6 transition-transform hover:scale-105">
                            <h3 className="text-xl font-semibold mb-2 text-gray-800">
                                Agendamentos em Tempo Real
                            </h3>
                            <p className="text-gray-600">
                                Permita que os clientes marquem, remarquem ou cancelem compromissos de forma simples e
                                com atualizações em tempo real.
                            </p>
                        </div>
                        <div
                            className="bg-gradient-to-br from-pink-50 to-pink-25 rounded-lg shadow-lg p-6 transition-transform hover:scale-105">
                            <h3 className="text-xl font-semibold mb-2 text-gray-800">
                                Resumos Personalizados de Chamadas
                            </h3>
                            <p className="text-gray-600">
                                Registe os detalhes essenciais das chamadas com resumos personalizados que ajudam a
                                tomar decisões práticas.
                            </p>
                        </div>
                        <div
                            className="bg-gradient-to-br from-pink-50 to-pink-25 rounded-lg shadow-lg p-6 transition-transform hover:scale-105">
                            <h3 className="text-xl font-semibold mb-2 text-gray-800">
                                Automação Avançada de Vendas
                            </h3>
                            <p className="text-gray-600">
                                Automatize o alcance a potenciais clientes, mensagens personalizadas e contactos
                                posteriores para
                                aumentar as taxas de conversão.
                            </p>
                        </div>
                        <div
                            className="bg-gradient-to-br from-pink-50 to-pink-25 rounded-lg shadow-lg p-6 transition-transform hover:scale-105">
                            <h3 className="text-xl font-semibold mb-2 text-gray-800">
                                Integração Perfeita com Sistemas CRM
                            </h3>
                            <p className="text-gray-600">
                                Integre facilmente com os sistemas de CRM existentes para melhorar a recolha de dados e
                                a gestão de clientes.
                            </p>
                        </div>
                    </div>
                </div>

            </section>

            <section id="why-scaling" className="py-20 bg-white">
                <div className="container mx-auto px-4">
                    <h2 className="text-4xl font-bold mb-12 text-center bg-gradient-to-r from-purple-600 to-pink-500 text-transparent bg-clip-text">
                        Porquê escolher a Scaling?
                    </h2>

                    <div className="flex flex-col lg:flex-row items-start justify-between">
                        <div className="lg:w-1/2 mb-8 lg:mb-0 lg:pr-8"><h3
                            className="text-3xl font-bold mb-6 text-gray-800">Scaling oferece:</h3>
                            <ul className="mb-6 space-y-2">
                                <li className="flex items-center">
                                    <FontAwesomeIcon icon={faCheck} className="text-green-600 me-2"/>
                                    <span className="text-gray-700">Conformidade com RGPD e ISO 27001</span></li>
                                <li className="flex items-center">
                                    <FontAwesomeIcon icon={faCheck} className="text-green-600 me-2"/>
                                    <span className="text-gray-700">Elevada Fiabilidade</span></li>
                                <li className="flex items-center">
                                    <FontAwesomeIcon icon={faCheck} className="text-green-600 me-2"/>
                                    <span className="text-gray-700">Monitorização Contínua</span></li>
                                <li className="flex items-center">
                                    <FontAwesomeIcon icon={faCheck} className="text-green-600 me-2"/>
                                    <span className="text-gray-700">Apoio ao Cliente Incomparável</span></li>
                            </ul>
                            <p className="text-lg text-gray-700 mb-6">
                                <strong>Compromisso inabalável com a segurança e a fiabilidade.</strong>
                            </p>
                            <p className="text-gray-700">
                                Mantemos de forma firme os mais elevados padrões de segurança e fiabilidade, garantindo
                                que a nossa tecnologia de atendimento telefónico com IA oferece consistentemente
                                benefícios seguros e confiáveis a consumidores, empresas e à sociedade.
                            </p>
                        </div>
                        <div className="lg:w-1/2 w-full">
                            <div className="bg-gray-900 p-4 rounded-lg shadow-lg">
                                <div className="relative aspect-w-16 aspect-h-9">
                                    <iframe width="560" height="315"
                                            className="w-full"
                                            src="https://www.youtube.com/embed/D0UnqGm_miA?si=z8X-vZpJJNjuDOPn"
                                            title="Apresentação da Scaling" frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="solutions" className="py-20 bg-gradient-to-br from-pink-100 to-purple-200 overflow-hidden">
                <div className="container mx-auto px-4">
                    <h2 className="text-4xl font-bold mb-12 text-center bg-gradient-to-r from-purple-600 to-pink-500 text-transparent bg-clip-text">
                        Experimente o Scaling em Ação
                    </h2>
                    <p className="text-xl text-gray-700 mb-12 text-center max-w-3xl mx-auto">
                        Escute exemplos reais de como o Scaling gere chamadas de forma perfeita em vários setores,
                        revolucionando as interações com os clientes.
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-6">
                        <div
                            className="bg-gradient-to-br from-blue-400 to-blue-600 rounded-lg shadow-lg p-6 w-full h-full flex flex-col items-center justify-between text-white transition-all duration-300 hover:shadow-xl group select-none">
                            <div className="flex flex-col items-center justify-center flex-grow w-full">
                                <h3 className="text-xl font-semibold text-center mb-2">Saúde</h3>
                                <p className="text-center text-sm">Clínica Médica</p>
                            </div>
                            <button
                                className="mt-3 px-4 py-2 bg-white text-gray-800 rounded-full hover:bg-gray-200 transition-colors">
                                <FontAwesomeIcon icon={faPlay} className=""/>
                            </button>
                        </div>
                        <div
                            className="bg-gradient-to-br from-green-400 to-green-600 rounded-lg shadow-lg p-6 w-full h-full flex flex-col items-center justify-between text-white transition-all duration-300 hover:shadow-xl group select-none">
                            <div className="flex flex-col items-center justify-center flex-grow w-full">
                                <h3 className="text-xl font-semibold text-center mb-2">Recursos Humanos</h3>
                                <p className="text-center text-sm">Formação de Colaboradores</p>
                            </div>
                            <button
                                className="mt-3 px-4 py-2 bg-white text-gray-800 rounded-full hover:bg-gray-200 transition-colors">
                                <FontAwesomeIcon icon={faPlay} className=""/>
                            </button>
                        </div>
                        <div
                            className="bg-gradient-to-br from-yellow-400 to-yellow-600 rounded-lg shadow-lg p-6 w-full h-full flex flex-col items-center justify-between text-white transition-all duration-300 hover:shadow-xl group select-none">
                            <div className="flex flex-col items-center justify-center flex-grow w-full">
                                <h3 className="text-xl font-semibold text-center mb-2">Restaurantes</h3>
                                <p className="text-center text-sm">Reserva de Mesa</p>
                            </div>
                            <button
                                className="mt-3 px-4 py-2 bg-white text-gray-800 rounded-full hover:bg-gray-200 transition-colors">
                                <FontAwesomeIcon icon={faPlay} className=""/>
                            </button>
                        </div>
                        <div
                            className="bg-gradient-to-br from-red-400 to-red-600 rounded-lg shadow-lg p-6 w-full h-full flex flex-col items-center justify-between text-white transition-all duration-300 hover:shadow-xl group select-none">
                            <div className="flex flex-col items-center justify-center flex-grow w-full">
                                <h3 className="text-xl font-semibold text-center mb-2">Take-Away</h3>
                                <p className="text-center text-sm">Encomenda de Comida</p>
                            </div>
                            <button
                                className="mt-3 px-4 py-2 bg-white text-gray-800 rounded-full hover:bg-gray-200 transition-colors">
                                <FontAwesomeIcon icon={faPlay} className=""/>
                            </button>
                        </div>
                        <div
                            className="bg-gradient-to-br from-purple-400 to-purple-600 rounded-lg shadow-lg p-6 w-full h-full flex flex-col items-center justify-between text-white transition-all duration-300 hover:shadow-xl group select-none">
                            <div className="flex flex-col items-center justify-center flex-grow w-full">
                                <h3 className="text-xl font-semibold text-center mb-2">Recrutamento</h3>
                                <p className="text-center text-sm">Entrevista de Seleção</p>
                            </div>
                            <button
                                className="mt-3 px-4 py-2 bg-white text-gray-800 rounded-full hover:bg-gray-200 transition-colors">
                                <FontAwesomeIcon icon={faPlay} className=""/>
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-20">
                <div className="container mx-auto px-4">
                    <div className="max-w-3xl">
                        <h2 className="text-4xl font-bold mb-12 text-left bg-gradient-to-r from-purple-600 to-pink-500 text-transparent bg-clip-text">
                            Preparado para levar o seu negócio mais longe com agentes IA?
                        </h2>

                        <p className="text-xl text-gray-600 mb-8">Vamos torna-lo realidade.</p>

                        <div className="relative inline-block">
                            <a href="/book" target="_blank" rel="noopener noreferrer"
                               className="relative inline-flex items-center px-6 py-3 bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-full hover:from-blue-600 hover:to-purple-600 transition-colors font-semibold text-lg z-10">
                                <FontAwesomeIcon icon={faPhone} className="me-2"/>
                                Agende uma chamada connosco
                                <FontAwesomeIcon icon={faChevronRight} className="ms-2"/>
                            </a></div>
                    </div>
                </div>
            </section>

            <Footer/>
        </div>
    )
};

export default Home;